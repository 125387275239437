<template>
    <div
        class="flex flex-col md:flex-row gap-4 max-w-2xl justify-between mx-auto items-center bg-gray-100 p-8 rounded-md">
        <div class="md:max-w-[23ch] text-center md:text-left">
            <span class="text-lg font-bold leading-8 pw-text-black">Share new articles with me each month</span>
        </div>
        <div>
            <form @submit="onSubmit">
                <label class="flex">
                    <input type="email" v-model="form.email"
                        class="bg-white rounded-es-xl border-2 border-secondary-500 w-full p-2"
                        placeholder="Your email" />
                    <button class="bg-secondary-500 text-white px-4 rounded-se-xl">Submit</button>
                </label>
            </form>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios'

const form = ref({
    newsletter: true,
    email: useState('bannerNewsletter')?.value?.email || '',
})

useState('bannerNewsletter', () => form)

const onSubmit = async (ev) => {
    ev.preventDefault()

    await axios.post("https://usebasin.com/f/d87501448683", form.value)
        .then(() => {
            const { $showToast } = useNuxtApp();
            $showToast(`Thank you, we will send fresh articles to ${form.value.email} each month!`, 'success', 3000);
        }).catch((e) => {
            const { $showToast } = useNuxtApp();
            $showToast('Whoops, something went wrong!', 'error', 3000);
        })
}
</script>
